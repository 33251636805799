<template>
  <CustomTable
    icon="fa-users"
    title="New Users"
    :provider="getAllUsers"
    :headers="headers"
  >
    <template #item.createdAt="{ item }">
      {{ formatDate(item.createdAt) }}
    </template>
    <template #item.zohoId="{ item }">
      <OpenInZohoButton v-if="item.zohoId" :zoho-id="item.zohoId" type="user" />
    </template>
    <template #item.demo="{ item }">
      <OpenInNewWindowButton
        :target-link="`/demos/users/${item.email}`"
        hint="Click to open as a demo"
        icon="fas fa-desktop"
      />
    </template>
    <template #item.fullname="{ item }">
      <span
        class="cursor-pointer primary--text"
        @click="openUserDialog(item.email)"
      >
        {{ item.fullname }}
      </span>
      <ImpersonateUserButton :email="item.email" />
    </template>
    <template #item.companyName="{ item }">
      <NuxtLink :to="`companies/${item.companyId}`">{{
        item.companyName
      }}</NuxtLink>
    </template>
  </CustomTable>
</template>

<script>
import CustomTable from "@/components/homePage/CustomTable"
import OpenInZohoButton from "@/components/OpenInZohoButton"
import OpenInNewWindowButton from "@/components/OpenInNewWindowButton"
import ImpersonateUserButton from "@/components/ImpersonateUserButton"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { useUserStore } from "@/stores/users"

export default {
  name: "UsersTable",
  components: {
    CustomTable,
    OpenInZohoButton,
    OpenInNewWindowButton,
    ImpersonateUserButton,
  },
  data() {
    return {
      headers: [
        {
          text: "Name",
          value: "fullname",
        },
        {
          text: "Zoho",
          value: "zohoId",
          align: "center",
          sortable: false,
          width: 40,
        },
        {
          text: "Demo",
          value: "demo",
          align: "center",
          sortable: false,
          width: 40,
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Persona",
          value: "persona",
          width: 75,
        },
        {
          text: "Company",
          value: "companyName",
        },
        {
          text: "Created At",
          value: "createdAt",
        },
      ],
    }
  },
  computed: {
    getAllUsers() {
      return AdminApi.users.getUsers
    },
  },
  methods: {
    openUserDialog(email) {
      useUserStore().openDialog(email)
    },
  },
}
</script>

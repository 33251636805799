<template>
  <div>
    <CustomTable
      icon="fa-camera"
      title="New Cameras"
      :provider="provider"
      :headers="headers"
    >
      <template #item.name="{ item }">
        <span
          class="cursor-pointer primary--text"
          @click="cameraDialogStore.openDialog({ camera: item.exid })"
          >{{ item.name }}
        </span>
      </template>
      <template #item.status="{ item }">
        <span :class="getStatusColor(item.status)">
          {{ getStatusText(item.status) }}
        </span>
      </template>
      <template #item.zohoId="{ item }">
        <OpenInZohoButton
          v-if="item.zohoId"
          :zoho-id="item.zohoId"
          type="camera"
        />
      </template>
      <template #item.demo="{ item }">
        <OpenInNewWindowButton
          :target-link="`/demos/cameras/${item.exid}`"
          hint="Click to open as a demo"
          icon="fas fa-desktop"
        />
      </template>
      <template #item.fullname="{ item }">
        <div v-if="item.userFullname">
          {{ item.userFullname }}
          <ImpersonateUserButton
            :email="item.userEmail"
            :target-route="`/projects/${item.projectExid}/${item.exid}`"
          />
        </div>
        <span v-else>-</span>
      </template>
      <template #item.projectName="{ item }">
        <div v-if="item.projectName" class="d-flex align-center">
          <span
            class="cursor-pointer primary--text"
            @click="projectStore.openDialog(item.projectExid)"
          >
            {{ item.projectName }}
          </span>
        </div>
        <span v-else>-</span>
      </template>
    </CustomTable>
  </div>
</template>

<script>
import CustomTable from "@/components/homePage/CustomTable"
import CameraUtils from "@/mixins/cameraUtils"
import OpenInZohoButton from "@/components/OpenInZohoButton"
import OpenInNewWindowButton from "@/components/OpenInNewWindowButton"
import ImpersonateUserButton from "@/components/ImpersonateUserButton"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/projects"
import { useCameraDialogStore } from "@/stores/cameraDialog"

export default {
  name: "CamerasTable",
  components: {
    CustomTable,
    OpenInZohoButton,
    OpenInNewWindowButton,
    ImpersonateUserButton,
  },
  mixins: [CameraUtils],
  data() {
    return {
      headers: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "ID",
          value: "exid",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Zoho",
          value: "zohoId",
          align: "center",
          sortable: false,
          width: 40,
        },
        {
          text: "Demo",
          value: "demo",
          align: "center",
          sortable: false,
          width: 40,
        },
        {
          text: "Owner",
          value: "fullname",
        },
        {
          text: "Project",
          value: "projectName",
        },
        {
          text: "Created At",
          value: "createdAt",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useCameraDialogStore, useProjectStore),
    provider() {
      return AdminApi.cameras.getCameras
    },
  },
}
</script>

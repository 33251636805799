<template>
  <div>
    <CustomTable
      icon="fa-camera"
      title="Oldest Offline Cameras"
      :provider="provider"
      :providerParams="{
        sort: 'last_online_at|asc',
      }"
      :headers="headers"
    >
      <template #item.name="{ item }">
        <span
          class="primary--text cursor-pointer"
          @click="cameraDialogStore.openDialog({ camera: item.exid })"
        >
          {{ item.name }}
        </span>
      </template>
      <template #item.status="{ item }">
        <span :class="getStatusColor(item.status)">
          {{ getStatusText(item.status) }}
        </span>
      </template>
      <template #item.zohoId="{ item }">
        <OpenInZohoButton
          v-if="item.zohoId"
          :zoho-id="item.zohoId"
          type="camera"
        />
      </template>
      <template #item.lastOnlineAt="{ item }">
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{ $moment.tz(item.lastOnlineAt, item.timezone).fromNow() }}
            </span>
          </template>
          <span>
            {{ $moment.tz(item.lastOnlineAt, item.timezone) }}
          </span>
        </v-tooltip>
      </template>
    </CustomTable>
  </div>
</template>

<script>
import CustomTable from "@/components/homePage/CustomTable"
import CameraUtils from "@/mixins/cameraUtils"
import OpenInZohoButton from "@/components/OpenInZohoButton"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useCameraDialogStore } from "@/stores/cameraDialog"

export default {
  name: "OfflineCamerasTable",
  components: {
    CustomTable,
    OpenInZohoButton,
  },
  mixins: [CameraUtils],
  data() {
    return {
      headers: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "ID",
          value: "exid",
          width: 90,
        },
        {
          text: "Status",
          value: "status",
          width: 120,
        },
        {
          text: "Last Online At",
          value: "lastOnlineAt",
        },
        {
          text: "Zoho",
          value: "zohoId",
          align: "center",
          sortable: false,
          width: 40,
        },
        {
          text: "Owner",
          value: "fullname",
        },
        {
          text: "Created At",
          value: "createdAt",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useCameraDialogStore),
    provider() {
      return AdminApi.offlineCameras.getOfflineCameras
    },
  },
}
</script>

<template>
  <div
    class="stats--card d-flex align-center ml-2 px-2 mb-2"
    :style="`border: ${color} 1px solid`"
  >
    <div class="stats--count--dot" :style="`background: ${color}`"></div>
    <div class="stats--count stats--text">
      {{ title }}
    </div>
    <div class="stats--text ml-4">
      {{ value }}
    </div>
  </div>
</template>

<script>
export default {
  name: "CardStat",
  props: {
    color: {
      type: String,
      default: () => "",
    },
    title: {
      type: String,
      default: () => "",
    },
    value: {
      type: [String, Number],
      default: () => "",
    },
  },
}
</script>

<style lang="scss" scoped>
.stats--card {
  width: max-content;
  border-radius: 50px;
}
.stats--count {
  position: relative;
  margin-left: 7px;
}
.stats--text {
  font-size: 12px;
}
.stats--count--dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
</style>

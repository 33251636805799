<template>
  <div class="home-page">
    <ERow class="home-page__search mx-2">
      <ECol :cols="cols" :class="{ 'mb-n4': $vuetify.breakpoint.xsOnly }">
        <!-- Cameras -->
        <Autocomplete
          v-model="camera"
          item-value="exid"
          item-text="name"
          label="Cameras"
          :menu-props="{
            contentClass: 'search-menu',
          }"
          hide-details
          append-icon="fa-camera"
          no-filter
          :cache-items="false"
          resource="cameras"
          @change="goToResource('cameras')"
        >
          <template #item="{ item, on, attrs }">
            <v-list-item two-line dense v-bind="attrs" v-on="on">
              <v-list-item-content class="py-1">
                <v-list-item-title>
                  <span class="primary--text">{{ item.name }}</span>
                </v-list-item-title>
                <v-list-item-subtitle>{{ item.exid }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </Autocomplete>
      </ECol>

      <ECol :cols="cols" :class="{ 'mb-n4': $vuetify.breakpoint.xsOnly }">
        <!-- Users -->
        <Autocomplete
          v-model="user"
          item-value="email"
          item-text="name"
          label="Users"
          :menu-props="{
            contentClass: 'search-menu',
          }"
          hide-details
          append-icon="far fa-user"
          no-filter
          :cache-items="false"
          resource="users"
          @change="goToResource('users')"
        >
          <template #item="{ item, on, attrs }">
            <v-list-item two-line dense v-bind="attrs" v-on="on">
              <v-list-item-content class="py-1">
                <v-list-item-title>
                  <span class="primary--text">{{ item.name }}</span>
                </v-list-item-title>
                <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </Autocomplete>
      </ECol>

      <ECol :cols="cols" :class="{ 'mb-n4': $vuetify.breakpoint.xsOnly }">
        <!-- Companies -->
        <Autocomplete
          v-model="company"
          item-value="exid"
          item-text="name"
          label="Companies"
          :menu-props="{
            contentClass: 'search-menu',
          }"
          hide-details
          append-icon="far fa-building"
          no-filter
          :cache-items="false"
          resource="companies"
          @change="goToResource('companies')"
        >
          <template #item="{ item, on, attrs }">
            <v-list-item two-line dense v-bind="attrs" v-on="on">
              <v-list-item-content class="py-1">
                <v-list-item-title>
                  <span class="primary--text">{{ item.name }}</span>
                </v-list-item-title>
                <v-list-item-subtitle>{{ item.exid }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </Autocomplete>
      </ECol>

      <ECol :cols="cols" :class="{ 'mb-n1': $vuetify.breakpoint.xsOnly }">
        <!-- Projects -->
        <Autocomplete
          v-model="project"
          item-value="exid"
          item-text="name"
          label="Projects"
          :menu-props="{
            contentClass: 'search-menu',
          }"
          hide-details
          append-icon="fa-sitemap"
          no-filter
          :cache-items="false"
          resource="projects"
          @change="goToResource('projects')"
        >
          <template #item="{ item, on, attrs }">
            <v-list-item two-line dense v-bind="attrs" v-on="on">
              <v-list-item-content class="py-1">
                <v-list-item-title>
                  <span class="primary--text">{{ item.name }}</span>
                </v-list-item-title>
                <v-list-item-subtitle>{{ item.exid }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </Autocomplete>
      </ECol>
    </ERow>
    <v-divider></v-divider>
    <div class="home-page__content">
      <!--  Cameras stats-->
      <ERow no-gutter class="px-1">
        <ECol cols="12">
          <CamerasStats title="Cameras" />
        </ECol>

        <ECol cols="12">
          <CamerasStats
            title="Construction Cameras"
            byEmail="construction@evercam.io"
          />
        </ECol>

        <!-- Projects stats -->
        <ECol cols="12">
          <ProjectsStats />
        </ECol>
      </ERow>
      <v-divider></v-divider>

      <!-- Tables -->
      <ERow class="my-5 px-1">
        <ECol cols="12">
          <UsersTable />
        </ECol>

        <ECol cols="12">
          <CamerasTable />
        </ECol>

        <ECol cols="12">
          <OfflineCamerasTable />
        </ECol>
      </ERow>
    </div>
  </div>
</template>

<script>
import { toQueryString } from "@evercam/shared/utils"
import Autocomplete from "@evercam/shared/components/Autocomplete"
import CamerasTable from "@/components/homePage/CamerasTable"
import OfflineCamerasTable from "@/components/homePage/OfflineCamerasTable"
import UsersTable from "@/components/homePage/UsersTable"
import CamerasStats from "@/components/homePage/CamerasStats"
import ProjectsStats from "@/components/homePage/ProjectsStats"

export default {
  name: "Index",
  components: {
    Autocomplete,
    CamerasTable,
    OfflineCamerasTable,
    UsersTable,
    CamerasStats,
    ProjectsStats,
  },
  data() {
    return {
      camera: null,
      user: null,
      company: null,
      project: null,
    }
  },
  computed: {
    cols() {
      return this.$vuetify.breakpoint.xsOnly ? 12 : 3
    },
  },
  methods: {
    goToResource(resource) {
      let route = ""
      const defaultParams = {
        sort: "created_at|desc",
        limit: 50,
        page: 1,
      }
      let params = {}
      let subQuery = ""
      switch (resource) {
        case "users":
          route = "/users"
          params.email = this.user.email
          break
        case "companies":
          route = "/companies"
          params.exid = this.company.exid
          break
        case "cameras":
          route = "/cameras"
          params.cameraExid = this.camera?.exid
          subQuery = toQueryString({
            dialog: true,
            exid: this.camera?.exid,
            tab: 0,
          })
          break
        case "projects":
          route = "/projects"
          params.exid = this.project?.exid
          params.sort = "inserted_at|desc"
          break
      }

      window
        .open(
          `${route}?${toQueryString({ ...defaultParams, ...params })}${
            subQuery ? "#" : ""
          }${subQuery}`,
          "_self"
        )
        .focus()
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@evercam/shared/styles/mixins";
@import "~vuetify/src/styles/styles.sass";
.home-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  &__content {
    @include custom-scrollbar(rgb(186, 186, 186), rgb(231, 231, 231));
    overflow-x: hidden;
  }
  &__search .v-icon {
    transform: none !important;
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    max-height: calc(100vh - 45px);
  }
}

.search-menu .v-list {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.search-menu .v-list .v-list-item {
  padding-top: 2px;
  padding-bottom: 2px;
  min-height: auto !important;
}
</style>

<template>
  <div v-if="isLoading" class="d-flex grow align-center justify-center">
    <EvercamLoadingAnimation :verify-theme-mode="true" size="FourXl" />
  </div>

  <div v-else class="d-flex grow flex-column">
    <div class="d-flex align-center ml-3 mb-2">
      <v-icon class="grey--text text--darken-1 stats-icon pr-2 mb-1">
        fa-sitemap
      </v-icon>
      <div class="subtitle-1 grey--text text--darken-1 font-weight-medium mr-3">
        Projects
      </div>
    </div>
    <div class="d-flex">
      <ChipStat
        v-for="(object, index) in Object.values(projectsStats)"
        :key="index"
        :color="object.color"
        :title="object.title"
        :value="object.value"
        class="cursor-pointer"
        @click.native="openLink(object.status)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import ChipStat from "@/components/homePage/ChipStat"
import { ProjectStatus } from "@evercam/shared/types/project"
import Vue from "vue"
import { AdminApi } from "@evercam/shared/api/adminApi"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"

export default Vue.extend({
  name: "CamerasStats",
  components: {
    ChipStat,
    EvercamLoadingAnimation,
  },
  data() {
    return {
      projectsStats: {
        toStart: {
          color: "#fbc02d",
          title: "To Start",
          value: 0,
          status: ProjectStatus.ToStart,
        },
        inProgress: {
          color: "#388e3c",
          title: "In Progress",
          value: 0,
          status: ProjectStatus.InProgress,
        },
        completed: {
          color: "#0097a7",
          title: "Completed",
          value: 0,
          status: ProjectStatus.Completed,
        },
      },
      isLoading: false,
    }
  },
  mounted() {
    this.fetchProjectsStats()
  },
  methods: {
    async fetchProjectsStats() {
      try {
        this.isLoading = true
        let statuses = await AdminApi.projects.getProjectsStats()
        Object.keys(this.projectsStats).forEach(
          (status) => (this.projectsStats[status].value = statuses[status] || 0)
        )
      } catch (error) {
        this.$notifications.error({
          text: "Could not load projects stats!",
          error,
        })
      } finally {
        this.isLoading = false
      }
    },
    openLink(status) {
      let link = `/projects?sort=inserted_at%7Cdesc&limit=50&page=1&status=${status}`
      window.open(link, "_self").focus()
    },
  },
})
</script>

<style scoped>
.stats-icon {
  font-size: 15px !important;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-page"},[_c('ERow',{staticClass:"home-page__search mx-2"},[_c('ECol',{class:{ 'mb-n4': _vm.$vuetify.breakpoint.xsOnly },attrs:{"cols":_vm.cols}},[_c('Autocomplete',{attrs:{"item-value":"exid","item-text":"name","label":"Cameras","menu-props":{
          contentClass: 'search-menu',
        },"hide-details":"","append-icon":"fa-camera","no-filter":"","cache-items":false,"resource":"cameras"},on:{"change":function($event){return _vm.goToResource('cameras')}},scopedSlots:_vm._u([{key:"item",fn:function({ item, on, attrs }){return [_c('v-list-item',_vm._g(_vm._b({attrs:{"two-line":"","dense":""}},'v-list-item',attrs,false),on),[_c('v-list-item-content',{staticClass:"py-1"},[_c('v-list-item-title',[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(item.name))])]),_vm._v(" "),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.exid))])],1)],1)]}}]),model:{value:(_vm.camera),callback:function ($$v) {_vm.camera=$$v},expression:"camera"}})],1),_vm._v(" "),_c('ECol',{class:{ 'mb-n4': _vm.$vuetify.breakpoint.xsOnly },attrs:{"cols":_vm.cols}},[_c('Autocomplete',{attrs:{"item-value":"email","item-text":"name","label":"Users","menu-props":{
          contentClass: 'search-menu',
        },"hide-details":"","append-icon":"far fa-user","no-filter":"","cache-items":false,"resource":"users"},on:{"change":function($event){return _vm.goToResource('users')}},scopedSlots:_vm._u([{key:"item",fn:function({ item, on, attrs }){return [_c('v-list-item',_vm._g(_vm._b({attrs:{"two-line":"","dense":""}},'v-list-item',attrs,false),on),[_c('v-list-item-content',{staticClass:"py-1"},[_c('v-list-item-title',[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(item.name))])]),_vm._v(" "),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.email))])],1)],1)]}}]),model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}})],1),_vm._v(" "),_c('ECol',{class:{ 'mb-n4': _vm.$vuetify.breakpoint.xsOnly },attrs:{"cols":_vm.cols}},[_c('Autocomplete',{attrs:{"item-value":"exid","item-text":"name","label":"Companies","menu-props":{
          contentClass: 'search-menu',
        },"hide-details":"","append-icon":"far fa-building","no-filter":"","cache-items":false,"resource":"companies"},on:{"change":function($event){return _vm.goToResource('companies')}},scopedSlots:_vm._u([{key:"item",fn:function({ item, on, attrs }){return [_c('v-list-item',_vm._g(_vm._b({attrs:{"two-line":"","dense":""}},'v-list-item',attrs,false),on),[_c('v-list-item-content',{staticClass:"py-1"},[_c('v-list-item-title',[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(item.name))])]),_vm._v(" "),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.exid))])],1)],1)]}}]),model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}})],1),_vm._v(" "),_c('ECol',{class:{ 'mb-n1': _vm.$vuetify.breakpoint.xsOnly },attrs:{"cols":_vm.cols}},[_c('Autocomplete',{attrs:{"item-value":"exid","item-text":"name","label":"Projects","menu-props":{
          contentClass: 'search-menu',
        },"hide-details":"","append-icon":"fa-sitemap","no-filter":"","cache-items":false,"resource":"projects"},on:{"change":function($event){return _vm.goToResource('projects')}},scopedSlots:_vm._u([{key:"item",fn:function({ item, on, attrs }){return [_c('v-list-item',_vm._g(_vm._b({attrs:{"two-line":"","dense":""}},'v-list-item',attrs,false),on),[_c('v-list-item-content',{staticClass:"py-1"},[_c('v-list-item-title',[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(item.name))])]),_vm._v(" "),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.exid))])],1)],1)]}}]),model:{value:(_vm.project),callback:function ($$v) {_vm.project=$$v},expression:"project"}})],1)],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('div',{staticClass:"home-page__content"},[_c('ERow',{staticClass:"px-1",attrs:{"no-gutter":""}},[_c('ECol',{attrs:{"cols":"12"}},[_c('CamerasStats',{attrs:{"title":"Cameras"}})],1),_vm._v(" "),_c('ECol',{attrs:{"cols":"12"}},[_c('CamerasStats',{attrs:{"title":"Construction Cameras","byEmail":"construction@evercam.io"}})],1),_vm._v(" "),_c('ECol',{attrs:{"cols":"12"}},[_c('ProjectsStats')],1)],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('ERow',{staticClass:"my-5 px-1"},[_c('ECol',{attrs:{"cols":"12"}},[_c('UsersTable')],1),_vm._v(" "),_c('ECol',{attrs:{"cols":"12"}},[_c('CamerasTable')],1),_vm._v(" "),_c('ECol',{attrs:{"cols":"12"}},[_c('OfflineCamerasTable')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }